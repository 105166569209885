'use strict' 

var jwt = require('jwt-simple');
const  clv='35ffDghj89dW_w12gglLsv9e663667MfgsF5' 


exports.encode = function(item){
    if(!item) return ''
    item = JSON.stringify(item)
    let encode =  jwt.encode(item,clv)
    return encode;
}
exports.decode = function(item){
    if(!item) return ''
    let decode =  jwt.decode(item,clv)
    decode = JSON.parse(decode)
    return decode;
}